import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import {generatePath, useLoaderData, useNavigate} from "react-router-dom";
import {CreateChannel, UpdateChannel} from "../../api/methods";
import Grid from '@mui/material/Grid2';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import EmailIcon from "@mui/icons-material/Email";
import WebhookIcon from "@mui/icons-material/Webhook";
import SmsIcon from "@mui/icons-material/Sms";
import {Icon as MdiIcon} from '@mdi/react';
import {mdiSlack} from '@mdi/js';
import {apiErrorMapper} from "../apiErrorMapper";

export function ChannelForm(params) {
  const {channel: loadedChannel, selectedProject, selectedOrganization, integrations} = useLoaderData();
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const [channel, setChannel] = useState(loadedChannel);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const saveChannel = async () => {
    const payload = {
      name: channel.name,
      emails: channel.emails,
      webhooks: channel.webhooks,
      slacks: channel.slacks,
      smss: channel.smss,
    };

    let code, resp;
    if (channel.id) {
      [code, resp] = await UpdateChannel(selectedOrganization.id, selectedProject.id, channel.id, payload);
    } else {
      [code, resp] = await CreateChannel(selectedOrganization.id, selectedProject.id, payload);
    }

    const respErrors = apiErrorMapper(code, resp);
    if (respErrors) {
      setErrors(respErrors);
      return;
    }

    navigate(generatePath('/admin/organizations/:organizationID/projects/:projectID/channels', {
      organizationID: selectedOrganization.id,
      projectID: selectedProject.id
    }));
  }

  return (
    <Grid container spacing={2}>
      <Grid size={{xs: 12}}>
        <Typography variant="h4" sx={{pl: 1}}>
          {channel.id ? 'Edit channel' : 'New channel'}
        </Typography>
      </Grid>
      <Grid size={{xs: 12}}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <Grid size={{xs: 12, md: 6, lg: 4, xl: 3}}>
            <FormGroup sx={{mb: 2}}>
              <TextField id="outlined-basic" required label="Name" error={!!errors?.name} helperText={errors?.name}
                         variant="outlined" defaultValue={channel.name}
                         onChange={(e) => {
                           setChannel({...channel, name: e.target.value});
                         }}
              />
            </FormGroup>
            <Typography variant="h5">
              Notifications
              ({channel.emails.length + channel.webhooks.length + channel.slacks.length + channel.smss.length})
            </Typography>
          </Grid>
          <Grid size={{xs: 12, md: 6, lg: 4, xl: 3}}>
            <FormGroup sx={{mb: 2}}>
              <Button
                variant="text"
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                ADD NOTIFICATION
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={() => {
                  setChannel({...channel, emails: [...channel.emails, {name: '', email: ''}]});
                  handleClose();
                }}>
                  <ListItemIcon>
                    <EmailIcon fontSize="small"/>
                  </ListItemIcon>
                  Email</MenuItem>
                <MenuItem onClick={() => {
                  setChannel({...channel, webhooks: [...channel.webhooks, {name: '', url: ''}]});
                  handleClose();
                }}>
                  <ListItemIcon>
                    <WebhookIcon fontSize="small"/>
                  </ListItemIcon>Webhook</MenuItem>
                <MenuItem onClick={() => {
                  setChannel({...channel, smss: [...channel.smss, {name: '', phone: ''}]});
                  handleClose();
                }}>
                  <ListItemIcon>
                    <SmsIcon fontSize="small"/>
                  </ListItemIcon>SMS</MenuItem>
                <MenuItem onClick={() => {
                  setChannel({...channel, slacks: [...channel.slacks, {name: ''}]});
                  handleClose();
                }}>
                  <ListItemIcon>
                    <MdiIcon path={mdiSlack} size={1}/>
                  </ListItemIcon>
                  Slack</MenuItem>
              </Menu>
            </FormGroup>
          </Grid>
          <Grid container spacing={2} sx={{pl: 1}}>
            {channel.emails.map((email, idx) => (
              <Grid key={idx + '-email'} size={{xs: 12, md: 6, lg: 4, xl: 3}}>
                <Box sx={{border: 1, borderColor: 'grey.500', borderRadius: 1, p: 2, mb: 2}}>
                  <Typography variant="h6">Email</Typography>
                  <FormGroup sx={{my: 2}}>
                    <TextField id="outlined-basic" label="Name" required
                               error={!!errors?.["emails.[" + idx + "].name"]}
                               helperText={errors?.["emails.[" + idx + "].name"]}
                               variant="outlined" defaultValue={email.name}
                               onChange={(e) => {
                                 const newEmails = [...channel.emails];
                                 newEmails[idx].name = e.target.value;
                                 setChannel({...channel, emails: newEmails});
                               }}
                    />
                  </FormGroup>
                  <FormGroup sx={{mb: 2}}>
                    <TextField required id="outlined-basic" required
                               error={!!errors?.["emails.[" + idx + "].email"]}
                               helperText={errors?.["emails.[" + idx + "].email"]}
                               label="To" variant="outlined" defaultValue={email.email}
                               onChange={(e) => {
                                 const newEmails = [...channel.emails];
                                 newEmails[idx].email = e.target.value;
                                 setChannel({...channel, emails: newEmails});
                               }}
                    />
                  </FormGroup>
                  <Button variant="text"
                          onClick={() => {
                            setErrors({});
                            setChannel({...channel, emails: channel.emails.filter((_, i) => i !== idx)});
                          }}
                  >DELETE</Button>
                </Box>
              </Grid>
            ))}
            {channel.webhooks.map((webhook, idx) => (
              <Grid key={idx + '-webhook'} size={{xs: 12, md: 6, lg: 4, xl: 3}}>
                <Box sx={{border: 1, borderColor: 'grey.500', borderRadius: 1, p: 2, mb: 2}}>
                  <Typography variant="h6">Webhook</Typography>
                  <FormGroup sx={{my: 2}}>
                    <TextField id="outlined-basic" required
                               error={!!errors?.["webhooks.[" + idx + "].name"]}
                               helperText={errors?.["webhooks.[" + idx + "].name"]}
                               label="Name" variant="outlined" defaultValue={webhook.name}
                               onChange={(e) => {
                                 const newWebhooks = [...channel.webhooks];
                                 newWebhooks[idx].name = e.target.value;
                                 setChannel({...channel, webhooks: newWebhooks});
                               }}
                    />
                  </FormGroup>
                  <FormGroup sx={{mb: 2}}>
                    <TextField required id="outlined-basic"
                               error={!!errors?.["webhooks.[" + idx + "].url"]}
                               helperText={errors?.["webhooks.[" + idx + "].url"]}
                               label="URL" variant="outlined" defaultValue={webhook.url}
                               onChange={(e) => {
                                 const newWebhooks = [...channel.webhooks];
                                 newWebhooks[idx].url = e.target.value;
                                 setChannel({...channel, webhooks: newWebhooks});
                               }}
                    />
                  </FormGroup>
                  <Button variant="text"
                          onClick={() => {
                            setErrors({});
                            setChannel({...channel, webhooks: channel.webhooks.filter((_, i) => i !== idx)});
                          }}
                  >DELETE</Button>
                </Box>
              </Grid>
            ))}
            {channel.smss.map((sms, idx) => (
              <Grid key={idx + '-sms'} size={{xs: 12, md: 6, lg: 4, xl: 3}}>
                <Box sx={{border: 1, borderColor: 'grey.500', borderRadius: 1, p: 2, mb: 2}}>
                  <Typography variant="h6">SMS</Typography>
                  <FormGroup sx={{my: 2}}>
                    <TextField id="outlined-basic" required
                               error={!!errors?.["smss.[" + idx + "].name"]}
                               label="Name" variant="outlined" defaultValue={sms.name}
                               onChange={(e) => {
                                 const newSmss = [...channel.smss];
                                 newSmss[idx].name = e.target.value;
                                 setChannel({...channel, smss: newSmss});
                               }}
                    />
                  </FormGroup>
                  <FormGroup sx={{mb: 2}}>
                    <TextField required id="outlined-basic" required
                               error={!!errors?.["smss.[" + idx + "].phone"]}
                               helperText={errors?.["smss.[" + idx + "].phone"]}
                               label="Phone" variant="outlined" defaultValue={sms.phone}
                               onChange={(e) => {
                                 const newSmss = [...channel.smss];
                                 newSmss[idx].phone = e.target.value;
                                 setChannel({...channel, smss: newSmss});
                               }}
                    />
                    <FormHelperText>Example: +1 415 555 2671</FormHelperText>
                  </FormGroup>
                  <Button variant="text"
                          onClick={() => {
                            setErrors({});
                            setChannel({...channel, smss: channel.smss.filter((_, i) => i !== idx)});
                          }}>DELETE</Button>
                </Box>
              </Grid>
            ))}
            {channel.slacks.map((slack, idx) => (
              <Grid key={idx + '-slack'} size={{xs: 12, md: 6, lg: 4, xl: 3}}>
                <Box sx={{border: 1, borderColor: 'grey.500', borderRadius: 1, p: 2, mb: 2}}>
                  <Typography variant="h6">Slack</Typography>
                  <FormControl fullWidth sx={{my: 2}}>
                    <InputLabel id="slack-integration-select-label">Integration</InputLabel>
                    <Select
                      labelId="slack-integration-select-label"
                      id="slack-integration-select"
                      value={slack.integration_id ? slack.integration_id : ''}
                      label="Integration"
                      onChange={(e) => {
                        const newSlacks = [...channel.slacks];
                        newSlacks[idx].integration_id = e.target.value;
                        setChannel({...channel, slacks: newSlacks});
                      }}
                     variant="outlined">
                      {integrations.map((integration, idx) => (
                        integration.type === 'slack' &&
                        <MenuItem key={idx} value={integration.id}>{integration.slack.channel + " at " + integration.slack.team_name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button variant="text"
                          onClick={() => {
                            setErrors({});
                            setChannel({...channel, slacks: channel.slacks.filter((_, i) => i !== idx)});
                          }}
                  >DELETE</Button>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Grid size={{xs: 12, md: 6, lg: 4, xl: 3}}>
            <Button
              variant="text"
              onClick={() => saveChannel()}
            >{channel ? 'SAVE' : 'CREATE'}</Button>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
